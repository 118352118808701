<template>
  <div class="box-container">
    <v-row>
      <v-col>
        <div class="d-flex align-center mb-5">
          <v-avatar
            tile
            size="25"
            class="me-3"
          >
            <img
              src="@/assets/images/icons/star.svg"
              alt=""
            >
          </v-avatar>
          <h2 class="mb-0">
            {{ $t("Avis") }}
          </h2>
        </div>
      </v-col>
      <v-col cols="auto">
        <v-row justify-md="end">
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="filter.search"
              outlined
              dense
              hide-details
              :label="$t('Recherche...')"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-menu
              ref="dateMenu"
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filter.date"
                  :label="$t('Date')"
                  placeholder=""
                  outlined
                  dense
                  hide-details
                  prepend-inner-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="filter.date"
                range
                :active-picker.sync="activePicker"
              />
              <v-card-actions>
                <v-btn
                  text
                  small
                  @click.stop="dateMenu = false"
                >
                  {{ $t("Fermer") }}
                </v-btn>
                <v-spacer />
                <v-btn
                  depressed
                  color="primary"
                  small
                  @click.stop="dateMenu = false"
                >
                  {{ $t("Ok") }}
                </v-btn>
              </v-card-actions>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div class="d-none px-4 d-md-flex mb-5">
      <v-avatar
        size="50"
        class="mr-3"
      />
      <h4 class="font-600 grey--text text--darken-2 flex-1 mr-3">
        {{ $t("Article") }}
      </h4>
      <h4 class="font-600 grey--text text--darken-2 flex-1 mr-3">
        {{ $t("Commentaire") }}
      </h4>
      <h4 class="font-600 grey--text text--darken-2 flex-1 mr-3">
        {{ $t("Évaluation") }}
      </h4>
      <h4 class="font-600 grey--text text--darken-2 flex-1 mr-3">
        {{ $t("Date") }}
      </h4>
    </div>

    <v-data-iterator
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      hide-default-footer
      :loading="loading"
      :loading-text="$t('Chargement...')"
    >
      <template #item="{ item }">
        <v-row>
          <v-col
            cols="12"
          >
            <a
              :href="`/products/${item.id}`"
              class="text-decoration-none"
            >
              <base-card class="">
                <div class="pa-4 d-flex align-center flex-wrap">
                  <v-avatar
                    size="50"
                    class="mr-3"
                  >
                    <v-img
                      lazy-src="@/assets/images/products/imageshoes.png"
                      :src="item.product.thumb || '@/assets/images/products/imageshoes.png'"
                      alt=""
                    />
                  </v-avatar>
                  <div class="flex-1 mr-3">
                    <h4 class="font-600 mb-0 ">{{ item.product.name }}</h4>
                  </div>
                  <div class="flex-1 mr-3">
                    <p class="mb-0">{{ item.comment }}</p>
                  </div>
                  <div class="flex-1 mr-3">
                    <p class="mb-0 d-flex"><v-rating
                      :value="item.rating"
                      readonly
                    />({{ item.rating }})</p>
                  </div>
                  <div class="flex-1 mr-3">
                    <p class="mb-0">{{ formatDate(item.created_at,{ month: 'short', day: 'numeric', year: 'numeric' }) }}</p>
                  </div>

                </div>
              </base-card>
            </a>
          </v-col>
        </v-row>
      </template>
      <!--      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.name"
            cols="12"
          >
            <a
              href="#"
              class="text-decoration-none"
            >
              <base-card class="">
                <div class="pa-4 d-flex align-center flex-wrap">
                  <div class="flex-1 mr-3">
                    <h4 class="font-600 mb-0 ">1050017AS</h4>
                  </div>
                  <div class="flex-1 mr-3">
                    <v-chip
                      class="ma-2"
                      color="grey lighten-2"
                      small
                    >
                      Primary
                    </v-chip>
                  </div>
                  <div class="flex-1 mr-3">
                    <p class="mb-0">jun 09, 2021</p>
                  </div>

                  <div class="flex-1 mr-3">
                    <p class="mb-0">$350.00</p>
                  </div>
                  <v-btn
                    icon
                    color="grey"
                    class=""
                    to="/order-details"
                  >
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </div>
              </base-card>
            </a>
          </v-col>
        </v-row>
      </template>-->

      <template v-slot:footer>
        <v-row
          class="my-5 me-1"
          align="center"
          justify="center"
        >
          <v-spacer />

          <span
            class="mr-4
                                grey--text"
          >
            {{ $t("Page") }} {{ page }} {{ $t("de") }} {{ numberOfPages }}
          </span>
          <v-btn
            :loading="loading"
            fab
            :disabled="page == 1"
            small
            color="primary"
            class="mr-1"

            @click="formerPage"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            :loading="loading"
            fab

            :disabled="page == numberOfPages"
            small
            color="primary"
            class="ml-1"
            @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
  </div>
</template>
<script>

  export default {
    components: {

    },
    props: {
    },
    data () {
      return {
        isSidebar: false,
        dateMenu: false,
        activePicker: null,
        filter: {
          status: null,
          search: '',
          date: null, /* [new Date(Date.now()).toISOString().substr(0, 10), new Date(Date.now()).toISOString().substr(0, 10)] */
        },
        page: 0,
        itemsPerPage: 5,
        pagination: { itemsPerPage: 5, page: 1 },
        items: [],
        total: 0,
        loading: false,
        timeout: null,
        range: [0, 100, 500, 1000],
        itemsTwo: ['Low to High', 'High to Low', 'Date'],
        search: '',
        url: '',

      }
    },
    computed: {
      numberOfPages () {
        return Math.ceil(this.total / this.itemsPerPage)
      },
      params (nv) {
        return {
          ...this.filter,
        }
      },
    },
    watch: {
      params: {
        handler () {
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.items = []
            this.total = 0
            this.page = 0
            this.getData().then(data => {
              this.items = data.items
              this.total = data.total
              if (this.page + 1 <= this.numberOfPages) this.page += 1
            })
          }, 1500)
        },
        deep: true,
      },
    },
    mounted () {
      this.url = this.$route.path
      this.getData().then(data => {
        this.items = data.items
        this.total = data.total
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      })
    },
    created () {
      window.addEventListener('scroll', this.handleScroll)
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      nextPage () {
        if (this.page + 1 <= this.numberOfPages) {
          this.getData().then(data => {
            for (var i = 0; i < data.items.length; i++) {
              var index = this.items.findIndex(e => e.id === data.items[i].id)
              if (index === -1) {
                this.items.push(data.items[i])
              } else {
                this.items[index] = data.items[i]
              }
            }
            // this.items.push(...data.items)

            this.total = data.total
            if (this.page + 1 <= this.numberOfPages) this.page += 1
          })
        }
      },
      formerPage () {
        if (this.page - 1 >= 1) this.page -= 1
      },
      season (val) {
        return this.range[val]
      },
      getData () {
        this.loading = true
        return new Promise((resolve, reject) => {
          console.log(this.pagination)
          var _this = this
          /* if(this.pagination.sortBy)
                               for(var i=0;i<this.pagination.sortBy.length;i++){

                                   orderBy.push({
                                       column:this.pagination.sortBy[i],
                                       direction:this.pagination.sortDesc[i]?"DESC":"ASC"
                                   });
                               } */

          // get by search keyword
          // eslint-disable-next-line no-undef
          axios.get(this.url, {
            params: {
              term: this.filter.search,
              date: this.filter.date,
              status: this.filter.status,
              orderBy: this.pagination.sortBy ? this.pagination.sortBy[0] : 'id',
              direction: (this.pagination.sortDesc) ? (this.pagination.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
              page: this.page + 1,
              per_page: this.pagination.itemsPerPage,
            },
          })
            .then(res => {
              const items = res.data.data
              const total = res.data.total
              this.loading = false
              resolve({
                items,
                total,
              })
            })
            .catch(err => console.log(err))
            // eslint-disable-next-line no-return-assign
            .finally(() => _this.loading = false)
        })
      },
    },
  }
</script>
